import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ImpressumPage = () => (
  <Layout>
    <SEO title="Impressum" lang="de" />
    <Container>
      <h1 className="h3">Impressum</h1>
      <hr className="featurette-divider" />
      <Row className="align-items-center">
        <Col xs={12} md={6}>
          <p className="font-weight-bold">
            Haben Sie nun Lust bekommen uns auch persönlich kennenzulernen?
          </p>
          <p>Dann freuen wir uns an unseren Schießabenden auf Sie!</p>

          <p>
            Schützenverein 1961 Gambach e.V. <br />
            Bahnhofstraße 88 <br />
            35516 Münzenberg <br />
          </p>
          <p>
            {/* Telefon: (0 60 33) 6 86 77 <br /> */}
            E-Mail:{" "}
            <a href="mailto:heiko.lippert1@gmx.de">heiko.lippert1@gmx.de</a>
          </p>
          <p>
            Finden Sie uns auf{" "}
            <a href="https://goo.gl/maps/MranKFYzuvRgzyhp6" target="_blank">
              Google Maps
            </a>
            .
          </p>
          <p>
            Eingetragen im Vereinsregister des Amtsgerichts Friedberg <br />
            Nummer des Vereins: VR 1257
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ImpressumPage
